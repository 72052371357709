<template>
  <b-card style="padding-top: 10px" class="tab-new-ui">
    <div>
      <div class="d-flex justify-content-between align-items-center mb-1">
        <div>
          <h4 class="card-title mb-0">
            {{
              action === "create"
                ? $t("sim.AddNewSIM")
                : action === "edit"
                ? $t("sim.UpdateSIM")
                : $t("sim.ViewSIM")
            }}
          </h4>
        </div>

        <b-button
          variant="outline-primary"
          size="sm"
          class="add-user-btn v2-back ml-10"
          @click="$router.go(-1)"
        >
          <feather-icon icon="ArrowLeftIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("sim.Back") }}</span>
        </b-button>
      </div>
      <validation-observer ref="operatorRules" #default="{ invalid }">
        <b-form>
          <div class="sensors-sc">
            <div class="add-card">
              <b-row>
                <b-col md="6" sm="12">
                  <validation-provider
                    name="MSISDN Number"
                    :rules="{
                      required: true,
                      min: 5,
                      max: 30,
                      min_value: 10000
                    }"
                    v-slot="{ errors }"
                  >
                    <b-form-group :label="$t('sim.MSISDN-Number*')">
                      <b-form-input
                        type="number"
                        v-model="sim.msisdn"
                        :placeholder="$t('sim.MSISDN-Number')"
                        name="MSISDN-Number"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6" sm="12">
                  <validation-provider
                    name="SIM Number"
                    :rules="{
                      required: true,
                      min: 5,
                      max: 30,
                      min_value: 10000
                    }"
                    v-slot="{ errors }"
                  >
                    <b-form-group :label="$t('sim.Sim-Number*')">
                      <b-form-input
                        type="number"
                        v-model="sim.sim_number"
                        :placeholder="$t('sim.Sim-Number')"
                        name="Sim-Number"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6" sm="12">
                  <validation-provider
                    name="Operator"
                    :rules="{
                      required: true,
                      min: 2,
                      max: 30
                    }"
                    v-slot="{ errors }"
                  >
                    <b-form-group :label="$t('sim.operator*')">
                      <b-form-input
                        v-model="sim.operator"
                        :placeholder="$t('sim.operator')"
                        name="Operator"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-group :label="$t('sim.activate_date*')">
                    <validation-provider
                      name="SIM activate date"
                      v-slot="{ errors }"
                      :rules="{
                        required: true
                      }"
                    >
                      <DatePicker
                        v-model="sim.sim_active_date"
                        :options="{ single_date: true, format: 'date_format' }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="add-card">
              <b-row>
                <b-col md="6" sm="12">
                  <b-form-group
                    :label="$t('unit.Labels')"
                    :disabled="!isEditable"
                  >
                    <TagMultiSelect
                      v-model="sim.labels"
                      :class="!isEditable ? 'disabled' : ''"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                  <validation-provider
                    name="Status"
                    :rules="{
                      required: true
                    }"
                    v-slot="{ errors }"
                  >
                    <b-form-group :label="$t('sim.Status*')">
                      <v-select
                        v-model="sim.status"
                        :options="statusOption"
                        :disabled="!isEditable"
                        :clearable="false"
                        :placeholder="$t('sim.selectStatus')"
                        :reduce="(option) => option.key"
                        label="label"
                      ></v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
            <div class="add-card" v-if="showRecharge">
              <b-form-checkbox
                class="mr-0 mt-50"
                v-model="isRecharge"
                name="is-rtl"
                switch
                inline
              />
              <b-row :class="isRecharge ? '' : 'disabled-recharge'">
                <b-col md="6" sm="12">
                  <validation-provider
                    name="Plan"
                    :rules="{ required: false }"
                    v-slot="{ errors }"
                  >
                    <b-form-group :label="$t('sim.plans')">
                      <b-form-input
                        v-model="recharge.plan"
                        :placeholder="$t('Enter Plans')"
                        name="plans"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6" sm="12">
                  <validation-provider
                    name="Amount"
                    :rules="{
                      required: false,
                      min_value: 0,
                      regex: /^\d+(\.\d+)?$/
                    }"
                    v-slot="{ errors }"
                  >
                    <b-form-group :label="$t('sim.amount')">
                      <b-form-input
                        v-model="recharge.amount"
                        :placeholder="$t('sim.Enter_amount')"
                        name="amount"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6" sm="12">
                  <validation-provider
                    name="Validity"
                    :rules="{
                      required: false
                    }"
                    v-slot="{ errors }"
                  >
                    <b-form-group :label="$t('sim.expiry_date*')">
                      <b-input-group size="md" append="Days">
                        <b-form-input
                          v-model="recharge.expiry_date"
                          :placeholder="$t('sim.Enter_validity')"
                          name="Validity"
                          type="number"
                          trim
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-group :label="$t('sim.last_recharge*')">
                    <validation-provider
                      name="Last recharge Date"
                      v-slot="{ errors }"
                    >
                      <div class="">
                        <DatePicker
                          customClass="date-last-recharge"
                          v-model="recharge.recharge_date"
                          :options="options"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="d-flex justify-content-right save-btn-footer">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="invalid"
              @click="saveSim"
            >
              {{ $t("device.Save") }}
            </b-button>
            <!-- <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          type="reset"
          class="mt-2"
        >
          {{ $t("device.Cancel") }}
        </b-button> -->
          </div></b-form
        >
      </validation-observer>
    </div>
    <Loader :show="show" />
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  VBTooltip,
  BAvatar,
  BTooltip,
  BCol,
  BRow,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";
import DatePicker from "./DatePicker.vue";
import moment from "moment";
import DeviceService from "@/libs/api/device-service";
import AccountService from "@/libs/api/account-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import TagMultiSelect from "@/layouts/components/TagMultiSelect.vue";
import Treeselect from "@riophae/vue-treeselect";
import { required, min, max } from "@validations";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Loader from "@/layouts/components/Loader.vue";
export default {
  components: {
    BCard,
    BButton,
    VBTooltip,
    BFormInput,
    vSelect,
    BAvatar,
    BTooltip,
    BCol,
    BRow,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BInputGroup,
    TagMultiSelect,
    Treeselect,
    Loader,
    BFormCheckbox
  },
  data() {
    return {
      isEditable: true,
      unitReAllocateTo: [],
      showRecharge: true,
      options: {
        single_date: true,
        format: "date_format",
        opens: "top"
      },
      action: "create",
      show: false,
      isRecharge: false,
      isFetchingOptions: false,
      sim: {
        sim_number: "",
        msisdn: "",
        operator: "",
        sim_active_date: null,
        labels: [],
        status: "ACTIVE"
      },
      recharge: { plan: "", amount: "", expiry_date: "", recharge_date: null },
      statusOption: [
        {
          label: this.$t("sim.StatusList.Active"),
          key: "ACTIVE"
        },
        {
          label: this.$t("sim.StatusList.InActive"),
          key: "INACTIVE"
        },
        {
          label: this.$t("sim.StatusList.Suspended"),
          key: "SUSPENDED"
        }
      ],
      constants: {
        MIN_DEVICE_NAME: 3,
        MAX_DEVICE_NAME: 50
      }
    };
  },
  mounted() {
    this.getChildAccounts();
    if (this.$route.name === "sim-edit") {
      this.action = "edit";
      this.getSimDetails();
      this.showRecharge = false;
    } else if (this.action === "sim-view") {
      this.action = "view";
      this.showRecharge = false;
      this.getSimDetails();
    }
  },
  methods: {
    // saveSim() {
    //   this.$refs.observer.validate().then((valid) => {
    //     if (valid) {
    //       // Here you would normally send sim to your API or save it locally
    //       this.$emit("add-sim", { ...this.sim });
    //       this.resetForm();
    //     }
    //   });
    // },
    handleMenuOpen() {
      this.$nextTick(() => {
        const menu = this.$refs.treeselect.getMenu();
        const hasReachedEnd =
          menu.scrollHeight - menu.scrollTop <= menu.clientHeight * 0.25;
        menu.addEventListener("scroll", () => {
          if (hasReachedEnd) this.fetchOptions();
        });
      });
    },

    async fetchOptions() {
      if (this.isFetchingOptions) return;
      this.isFetchingOptions = true;

      let response = await new AccountService().getChildAccounts({
        isChild: true,
        page: this.currentPage,
        page_size: parseInt(this.perPage),
        filterAccount: filterAccount || undefined
      });

      if (response && response.data) {
        this.unitReAllocateTo = response.data;
      } else if (response && response.error && response.error.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.title,
            text: response.error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
      this.isFetchingOptions = false;
    },
    searchAccount(filterAccount) {
      this.getChildAccounts(filterAccount);
    },
    async getChildAccounts(filterAccount) {
      try {
        let response = await new AccountService().getChildAccounts({
          isChild: true,
          filterAccount: filterAccount || undefined,
          page: this.currentPage,
          page_size: parseInt(this.perPage)
        });

        if (response && response.data && !this.isDevice) {
          //   this.getAccountDetail(response.data[0]);
          this.unitReAllocateTo = response.data;
        } else if (response && response.data && this.isDevice) {
          function recursive(data) {
            if (data && data.type.toUpperCase() == "CONSUMER") return true;

            if (data && data.children) {
              for (let i = 0; i < data.children.length; i++) {
                if (recursive(data.children[i])) {
                  // delete data.children[i];
                  data.children[i]["isDisabled"] = true;
                }
              }
            }
            return false;
          }
          recursive(response.data[0]);
          this.unitReAllocateTo = response.data;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    handleTreeSelect(e) {
      // this.changeInput({
      //   ...this.allocationData,
      //   unit_driver_id: null,
      //   ["user.id"]: null
      // });
    },
    async saveSim() {
      try {
        this.show = true;
        let requestData = { ...this.sim },
          response = null;
        if (this.isRecharge) {
          requestData = { ...requestData, ...this.recharge };
          requestData.expiry_date = moment(requestData.recharge_date).add(
            requestData.expiry_date,
            "days"
          );
          if (
            moment(this.recharge.recharge_date).isBefore(
              moment(this.sim.sim_active_date)
            )
          ) {
            this.show = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "",
                text: "The SIM activation date cannot be earlier than the recharge date",
                icon: "InfoIcon",
                variant: "danger"
              }
            });

            return;
          }
        }

        if (this.action === "edit") {
          requestData = {
            id: this.sim.id,
            status: this.sim.status,
            sim_number: this.sim.sim_number,
            operator: this.sim.operator,
            sim_active_date: this.sim.sim_active_date,
            msisdn: this.sim.msisdn
          };
          response = await new DeviceService().updateSim(requestData);
        } else {
          response = await new DeviceService().createSim(requestData);
        }
        this.show = true;
        // let response = await new UnitService().upsertOperator(requestData);
        if (response && response.data) {
          this.show = false;
          let title = this.$t("sim.simCreated");
          let message = "";
          if (this.sim && this.sim.id) {
            title = this.$t("sim.simUpdated");
            message = "";
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: title,
              text: message,
              icon: "EditIcon",
              variant: "success"
            }
          });

          this.$router.push({ name: "sim-list" });
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    resetForm() {
      this.sim = {
        sim_number: "",
        msisdn: "",
        operator: "",
        sim_active_date: null,
        labels: "",
        status: null
      };
      this.recharge = {
        plan: "",
        amount: "",
        expiry_date: "",
        recharge_date: null
      };
    },
    async getSimDetails() {
      // api call
      try {
        const id = this.$route.params.id;
        this.show = true;
        let requestData = {
          sim_id: id
        };
        let response = await new DeviceService().getSimDetails(requestData);

        if (response && response.data) {
          this.sim = response.data;
          this.show = false;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.dark-layout {
  .add-card {
    border: 1px solid #404656;
  }
}
.add-card {
  border-radius: 0.25rem;
  border: 1px solid #e1dddd;
  padding: 20px;
  margin-bottom: 20px;
}
.tab-new-ui {
  .sensors-sc {
    height: calc(100vh - 300px) !important ;
    overflow: auto;
    padding: 0 15px;
  }
}
.justify-content-right {
  justify-content: right;
}
.disabled-recharge {
  pointer-events: none !important;
  opacity: 0.4 !important;
}
</style>
