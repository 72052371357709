<template>
  <b-modal
    id="csv-upload-modal"
    hide-footer
    centered
    title="Add New SIM"
    size="lg"
    :scrollable="true"
    no-close-on-esc
    :hide-header="true"
    no-close-on-backdrop
    @hide="onHide"
    modal-class="map-view-model srt67 modal-add-sensors no-header-modal modal-add-sensors-v1"
  >
    <div class="tab-new-ui">
      <div class="slect-account-title-center">{{ $t("sim.UploadCSV") }}</div>
      <div class="formbold-file-item">
        <span class="formbold-file-name"> </span>
        <span
          class="curserPointer"
          @click="downloadJsonAsCsv(fileSampleData, 'sample-csv')"
          v-b-tooltip.hover.top="$t('sim.SampleCSVDownload')"
          ><feather-icon icon="DownloadCloudIcon" size="18"
        /></span>
      </div>
      <div class="sensor-modal-scroll">
        <div>
          <div class="formbold-main-wrapper">
            <div class="formbold-form-wrapper">
              <form :action="getSampleSVG()" method="get" target="_blank">
                <div class="mb-6">
                  <div
                    class="formbold-mb-5 formbold-file-input custom-upload-button"
                  >
                    <b-form-file
                      class="custom-file-input"
                      v-model="inputCsvFile"
                      @input="() => importCsv()"
                    />
                    <label>
                      <div>
                        <CSVIcon />

                        <span class="formbold-drop-file mt-2"
                          >{{ $t("sim.SelectCSV") }}
                        </span>
                      </div>
                    </label>
                  </div>
                  <div class="formbold-file-list formbold-mb-5" v-if="fileName">
                    <div class="formbold-file-item">
                      <span class="formbold-file-name"> {{ fileName }} </span>
                      <span
                        class="curserPointer"
                        @click="downloadJsonAsCsv(fileData, fileName)"
                        v-b-tooltip.hover.top="$t('Download')"
                      >
                        <feather-icon icon="DownloadCloudIcon" size="18"
                      /></span>
                    </div>
                  </div>
                  <div
                    class="formbold-file-list-filed formbold-mb-5"
                    v-if="failedFileName"
                  >
                    <div class="formbold-file-item">
                      <span class="formbold-file-name">
                        {{ failedFileName }}
                      </span>
                      <span
                        class="curserPointer"
                        @click="
                          downloadJsonAsCsv(failedFileData, failedFileName)
                        "
                        v-b-tooltip.hover.top="$t('Download Failed Records')"
                      >
                        <feather-icon icon="DownloadCloudIcon" size="18"
                      /></span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="d-flex justify-content-center" v-if="errors.length">
            <div class="w-75 mb-1" style="color: red">
              {{ $t("sim.CSVFailed") }}
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div
              class="save-btn-footer mr-2"
              :class="!errors.length && fileName ? '' : 'disabled'"
            >
              <b-button
                :disabled="!isImportDisable"
                @click="saveSim()"
                variant="primary"
              >
                {{ $t("sim.ImportSIM") }}</b-button
              >
            </div>
            <div class="save-btn-footer">
              <b-button @click="onHide()" variant="outline-secondary">
                {{ $t("Close") }}</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loader :show="show" />
  </b-modal>
</template>

<script>
import {
  BCard,
  BButton,
  VBTooltip,
  BAvatar,
  BTooltip,
  BCol,
  BRow,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormFile,
  BListGroup,
  BListGroupItem
} from "bootstrap-vue";
import CSVIcon from "@/assets/images/icons/SIM-icon/CSVIcon.svg";
import vSelect from "vue-select";
import DatePicker from "./DatePicker.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DeviceService from "@/libs/api/device-service";
import Loader from "@/layouts/components/Loader.vue";
export default {
  components: {
    BCard,
    BButton,
    VBTooltip,
    BFormInput,
    vSelect,
    BAvatar,
    BTooltip,
    BCol,
    BRow,
    DatePicker,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    CSVIcon,
    BFormFile,
    BListGroup,
    BListGroupItem,
    ToastificationContent,
    FeatherIcon,
    Loader
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      inputCsvFile: [],
      errors: [],
      fileName: "",
      fileData: null,
      fileSampleData: [
        {
          sim_number: 59364663,
          msisdn: 55695555,
          operator: "jio",
          sim_active_date: "2025-01-22T06:30:00.000Z",
          labels: "test",
          status: "ACTIVE"
        }
      ],
      failedFileName: "",
      failedFileData: null,
      show: false,
      isImportDisable: false
    };
  },
  props: ["selectedAccount"],
  methods: {
    importCsv(range = false) {
      try {
        if (this.inputCsvFile && this.inputCsvFile.name.includes(".csv")) {
          this.errors = "";

          this.errors = [];
          this.fileName = "";

          this.failedFileName = "";

          const reader = new FileReader();
          reader.onload = (res) => {
            this.fileName = this.inputCsvFile.name;
            this.isImportDisable = true;
            this.parseRangeCsv(res.target.result);
          };
          reader.onerror = (err) => console.log(err);
          reader.readAsText(this.inputCsvFile);
        }
        setTimeout(() => {
          this.inputCsvFile = [];
        }, 100);
      } catch (err) {
        console.log(err);
      }
    },
    parseRangeCsv(content) {
      try {
        if (!content) {
          return;
        }
        const lines = content
          .split("\n")
          .map((line) => line.trim())
          .filter((line) => line.length > 0);

        // Get the headers (first line of the CSV)
        const headers = lines[0].split(",");

        // Iterate through each line (starting from the second line)
        const records = lines.slice(1).map((line) => {
          const values = line.split(",");
          // Create an object with key-value pairs
          const record = headers.reduce((obj, header, index) => {
            obj[header] = values[index];
            return obj;
          }, {});
          return record;
        });
        this.fileData = records;
        console.log(records);
        const statusOptions = ["ACTIVE", "INACTIVE"];
        const errors = [];

        // Helper function to check if the date is valid
        const isValidDate = (dateStr) => !isNaN(new Date(dateStr).getTime());

        // Iterate over each record and validate
        records.forEach((recordObj, index) => {
          const error = {};
          let record = {
            sim_number: recordObj.sim_number,
            msisdn: recordObj.msisdn,
            operator: recordObj.operator,
            sim_active_date: recordObj.sim_active_date,
            labels: recordObj.labels,
            status: recordObj.status
          };
          record = Object.fromEntries(
            Object.entries(record).map(([key, value]) => [
              key,
              value.replace(/^"|"$/g, "")
            ])
          );

          // Validate MSISDN
          if (
            !record.msisdn ||
            Number(record.msisdn) < 0 ||
            record.msisdn.length < 5 ||
            record.msisdn.length > 30
          ) {
            error.msisdn = "MSISDN Number must be between 5 and 30 digits.";
          }

          // Validate SIM Number
          if (
            !record.sim_number ||
            Number(record.msisdn) < 0 ||
            record.sim_number.length < 5 ||
            record.sim_number.length > 30
          ) {
            error.sim_number = "SIM Number must be between 5 and 30 digits.";
          }

          // Validate Operator
          if (
            !record.operator ||
            record.operator.length < 2 ||
            record.operator.length > 30
          ) {
            error.operator = "Operator must be between 2 and 30 characters.";
          }

          // Validate SIM Active Date
          if (!record.sim_active_date || !isValidDate(record.sim_active_date)) {
            error.sim_active_date = "Invalid SIM Active Date.";
          }

          // Validate Status
          if (!statusOptions.includes(record.status)) {
            error.status = "Invalid status. Must be 'ACTIVE' or 'INACTIVE'.";
          }

          // Validate Plan
          // if (!record.plan) {
          //   error.plan = "Plan is required.";
          // }

          // Validate Amount

          record.error = " ";
          if (Object.keys(error).length > 0) {
            record.error = JSON.stringify(error).replace(/,/g, " ");
            errors.push({ sim_number: record.sim_number, error });
          }
        });
        //this.downloadJsonAsCsv(records);
        console.log(records);
        // Return success message if no errors found
        if (errors.length === 0) {
          console.log({ success: true, message: "All records are valid." });
        }
        this.errors = errors;
        // Return errors if validation fails
        console.log({ success: false, errors });
      } catch (err) {
        console.log(err);
      }
    },
    getSampleSVG() {
      return this.getCurrentPath() + "/app/assets/sim_records.csv";
    },
    onHide() {
      this.errors = "";
      this.inputCsvFile = [];
      this.errors = [];
      this.fileName = "";
      this.fileData = null;
      this.failedFileName = "";
      this.failedFileData = null;
      this.$bvModal.hide("csv-upload-modal");
    },
    downloadJsonAsCsv(jsonData, filename) {
      // Extract headers from the JSON data
      const headers = Object.keys(jsonData[0]);

      // Convert JSON data to CSV format
      const csv = [
        headers.join(","), // Join headers with commas
        ...jsonData.map((row) =>
          headers
            .map((fieldName) =>
              JSON.stringify(row[fieldName], (key, value) =>
                value === null ? "" : value
              )
            )
            .join(",")
        ) // Convert each row to a CSV string
      ].join("\r\n"); // Join all lines with a newline

      // Create a Blob from the CSV string and generate a link to download it
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");

      if (link.download !== undefined) {
        // Support for browsers that support download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    async saveSim() {
      try {
        const requestData = this.fileData.map((sim) => {
          const data = {
            sim_number: sim.sim_number,
            msisdn: sim.msisdn,
            operator: sim.operator,
            sim_active_date: sim.sim_active_date,
            labels: [sim.labels],
            status: sim.status,
            account_id:
              this.selectedAccount || localStorage.getItem("USER_ACCOUNT_ID")
          };
          return data;
        });
        this.show = true;
        let response = null;
        this.isImportDisable = false;
        response = await new DeviceService().bulkCreateSims({
          list: requestData,
          account_id: this.selectedAccount
        });
        // let response = await new UnitService().upsertOperator(requestData);
        if (response && response.data) {
          this.show = false;
          let title;
          let variant = "success";
          let message = "";
          if (response.data.failedSims && response.data.failedSims.length) {
            this.failedFileName = " failed_" + this.fileName;
            this.failedFileData = response.data.failedSims;
            this.$emit("refreshList", true);
            variant = "warning";
            title = `${requestData.length} records processed: ${
              response.data.createdSims.length
                ? response.data.createdSims.length + " imported successfully , "
                : ""
            }  ${response.data.failedSims.length} failed.`;
          } else if (
            !response.data.failedSims ||
            !response.data.failedSims.length
          ) {
            title = `${requestData.length} SIM’s Imported Successfully`;
            variant = "success";
            this.onHide();
            this.$emit("refreshList", true);
          } else {
            this.onHide();
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: title,
              text: message,
              icon: "EditIcon",
              variant: variant
            }
          });

          this.$router.push({ name: "sim-list" });
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    }
  }
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/colors/palette-variables.scss";
.srt67 {
  .modal-title {
    display: none;
  }
  .modal-header {
    background-color: transparent !important;
  }

  .close {
    margin: -1.3rem -1.4rem -0.8rem auto !important;
    box-shadow: 0px 4px 24px 0px rgba(34, 41, 47, 0.1) !important;
  }
}

.formbold-mb-5 {
  margin-bottom: 20px;
}
.formbold-pt-3 {
  padding-top: 12px;
}
.formbold-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 48px 0px 48px;
}

.formbold-form-wrapper {
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  // background: white;
}
.formbold-form-label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  margin-bottom: 12px;
}
.formbold-form-label-2 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}

.formbold-form-input {
  width: 100%;
  padding: 12px 24px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  // background: white;
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  outline: none;
  resize: none;
}
.formbold-form-input:focus {
  border-color: var(--primary);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold-btn {
  text-align: center;
  font-size: 16px;
  border-radius: 6px;
  padding: 14px 32px;
  border: none;
  font-weight: 600;
  background-color: var(--primary);
  color: white;
  cursor: pointer;
}
.formbold-btn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold--mx-3 {
  margin-left: -12px;
  margin-right: -12px;
}
.formbold-px-3 {
  padding-left: 12px;
  padding-right: 12px;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}

.formbold-file-input input {
  opacity: 0;
  cursor: pointer !important;
  position: absolute;
}
.formbold-file-input {
  cursor: pointer !important;
}
.formbold-file-input label {
  position: relative;
  border: 2px dashed var(--primary);
  border-radius: 6px;
  // min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
}
.formbold-drop-file {
  display: block;
  font-weight: 600;
  color: var(--primary);
  font-size: 20px;
  margin-bottom: 8px;
}

.formbold-or {
  font-weight: 500;
  font-size: 16px;
  color: var(--primary);
  display: block;
  margin-bottom: 8px;
}
.formbold-browse {
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  display: inline-block;
  padding: 8px 28px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.formbold-file-list {
  border-radius: 6px;
  background: #f5f7fb;
  padding: 16px 32px;
}
.formbold-file-list-filed {
  border-radius: 6px;
  background: #ff00002e;
  padding: 16px 32px;
}

.formbold-file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formbold-file-item button {
  color: #07074d;
  border: none;
  background: transparent;
  cursor: pointer;
}

.formbold-file-name {
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  padding-right: 12px;
}
.formbold-progress-bar {
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 8px;
  background: #e2e5ef;
}

.formbold-progress {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: var(--primary);
  border-radius: 8px;
}

.dark-layout {
  .formbold-file-list {
    border-radius: 6px;
    background: #333e55;
    padding: 16px 32px;
  }

  .formbold-file-name {
    color: white;
  }
  .formbold-file-item button {
    color: white;
  }
}

@media (min-width: 540px) {
  .sm\:w-half {
    width: 50%;
  }
}

@media only screen and (max-width: 540px) {
  .sm\:w-half {
    width: 50%;
  }
  .formbold-main-wrapper {
    padding: 0px !important;
    padding-top: -10px !important;
  }
  .sensor-modal-scroll .sensors-sc {
    // height: calc(100vh - 420px) !important;

    overflow: auto;
  }
  .modal-body {
    min-height: 100% !important ;
  }
}
.custom-upload-button {
  position: relative;
  cursor: pointer;
  .custom-file-input.b-form-file {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: $percent_100;
    height: $percent_100;
    opacity: 0;
    cursor: pointer;
  }
  .custom-file-label {
    cursor: pointer;
  }
}
</style>
